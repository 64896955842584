import React, { useMemo, useState } from 'react';
import filter from 'lodash/filter';
import sortBy from 'lodash/sortBy';
import size from 'lodash/size';
import take from 'lodash/take';

import { ThreeDStockIndexPageProductsCategoriesNavCategory } from '../../ThreeDStockIndexPageProductsCategoriesNav.types';

import { ThreeDStockIndexPageProductsCategoriesNavItem } from '../ThreeDStockIndexPageProductsCategoriesNavItem';
import { ThreeDStockIndexPageProductsCategoriesNavAmountButton } from '../ThreeDStockIndexPageProductsCategoriesNavAmountButton';

interface ThreeDStockIndexPageProductsCategoriesNavListProps {
  productCategories: ThreeDStockIndexPageProductsCategoriesNavCategory[];
}

function ThreeDStockIndexPageProductsCategoriesNavList({
  productCategories
}: ThreeDStockIndexPageProductsCategoriesNavListProps) {
  const listCategories = useMemo<
    ThreeDStockIndexPageProductsCategoriesNavCategory[]
  >(
    () =>
      sortBy(
        filter(productCategories, (category) => !category.parent),
        'localizedName'
      ),

    [productCategories]
  );

  const [showAll, setShowAll] = useState(false);

  if (size(productCategories) <= 5) {
    return (
      <>
        {listCategories.map((category) => (
          <ThreeDStockIndexPageProductsCategoriesNavItem
            key={category.id}
            category={category}
          />
        ))}
      </>
    );
  }

  const showedCategories = showAll ? listCategories : take(listCategories, 5);

  return (
    <>
      {showedCategories.map((category) => (
        <ThreeDStockIndexPageProductsCategoriesNavItem
          key={category.id}
          category={category}
        />
      ))}
      <ThreeDStockIndexPageProductsCategoriesNavAmountButton
        showAll={showAll}
        setShowAll={setShowAll}
      />
    </>
  );
}

export default ThreeDStockIndexPageProductsCategoriesNavList;
