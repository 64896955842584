import React, { useMemo, useState } from 'react';
import filter from 'lodash/filter';
import sortBy from 'lodash/sortBy';
import size from 'lodash/size';
import take from 'lodash/take';

import { ThreeDStockIndexPageLifestylesCategoriesNavCategory } from '../../ThreeDStockIndexPageLifestylesCategoriesNav.types';

import { ThreeDStockIndexPageLifestylesCategoriesNavItem } from '../ThreeDStockIndexPageLifestylesCategoriesNavItem';
import { ThreeDStockIndexPageLifestylesCategoriesNavAmountButton } from '../ThreeDStockIndexPageLifestylesCategoriesNavAmountButton';

interface ThreeDStockIndexPageLifestylesCategoriesNavListProps {
  lifestyleCategories: ThreeDStockIndexPageLifestylesCategoriesNavCategory[];
}

function ThreeDStockIndexPageLifestylesCategoriesNavList({
  lifestyleCategories
}: ThreeDStockIndexPageLifestylesCategoriesNavListProps) {
  const listCategories = useMemo<
    ThreeDStockIndexPageLifestylesCategoriesNavCategory[]
  >(
    () =>
      sortBy(
        filter(lifestyleCategories, (category) => !category.parent),
        'localizedName'
      ),

    [lifestyleCategories]
  );

  const [showAll, setShowAll] = useState(false);

  if (size(lifestyleCategories) <= 5) {
    return (
      <>
        {listCategories.map((category) => (
          <ThreeDStockIndexPageLifestylesCategoriesNavItem
            key={category.id}
            category={category}
          />
        ))}
      </>
    );
  }

  const showedCategories = showAll ? listCategories : take(listCategories, 5);

  return (
    <>
      {showedCategories.map((category) => (
        <ThreeDStockIndexPageLifestylesCategoriesNavItem
          key={category.id}
          category={category}
        />
      ))}
      <ThreeDStockIndexPageLifestylesCategoriesNavAmountButton
        showAll={showAll}
        setShowAll={setShowAll}
      />
    </>
  );
}

export default ThreeDStockIndexPageLifestylesCategoriesNavList;
